<template>
  <div
    class="diptyque"
    :class="{
      'diptyque--gutters': gutters,
      'diptyque--mobileSlider': mobileSlider
    }"
  >
    <div class="diptyque__scroller">
      <div
        v-for="(slide, i) in slides"
        ref="slides"
        :key="i"
        :class="`diptyque__slide__${i}`"
        class="diptyque__slide"
      >
        <v-caption v-if="slide.caption && slide.caption.top">
          {{ slide.caption.top }}
        </v-caption>
        <v-media
          class="cover__media"
          :src="slide.src"
          :lazy="slide.lazy"
          :srcset="slide.srcset"
          :sizes="slide.sizes"
          :ratio="slide.ratio"
          :media-link="slide.mediaLink"
        />
        <v-caption v-if="slide.caption && slide.caption.bottom">
          {{ slide.caption.bottom }}
        </v-caption>
      </div>
    </div>
    <div class="diptyque__pagination">
      <div :class="{'diptyque__bullet--active': currentIndex === 0 }" class="diptyque__bullet"></div>
      <div :class="{'diptyque__bullet--active': currentIndex === 1 }" class="diptyque__bullet"></div>
    </div>
    <!-- <div class="diptyque__slide diptyque__slide__1">
      <v-media
        class="cover__media"
        :src="slides[1].src"
        :lazy="slides[1].lazy"
        :srcset="slides[1].srcset"
        :sizes="slides[1].sizes"
        :ratio="slides[1].ratio"
        :media-link="slides[1].mediaLink"
      />
    </div> -->
  </div>
</template>

<script>
import VMedia from "./VMedia.vue"
import VCaption from "./VCaption.vue"

export default {
  name: "VDyptique",
  components: {
    VMedia,
    VCaption
  },
  data() {
    return {
      intersectionObserver: null,
      currentIndex: 0
    }
  },
  props: {
    mobileSlider: {
      type: Boolean,
      required: false,
      default: false
    },
    slides: {
      type: Array,
      required: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    ratio: {
      type: Number,
      default: 12 / 18
    },
    mediaLink: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver(
      entries =>
        entries.forEach(
          ({ isIntersecting, intersectionRatio, target }, i) => {
            if (isIntersecting && intersectionRatio >= 0.8) {
              this.currentIndex = this.$refs.slides.indexOf(target)
            }
          }
        ),
      { threshold: 0.8 }
    )
    this.$refs.slides.forEach((slide) => this.intersectionObserver.observe(slide))
  }
}
</script>

<style lang="sass">
.diptyque
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: 1fr
  grid-column-gap: var(--gutter)
  grid-row-gap: 0px

  &__scroller
    display: content

  .caption
    margin: 57px 0
    text-align: center
    max-width: 100% !important

  &--gutters
    padding: 0 var(--gutter)

  &__slide

  &__slide__0
    display: flex
    flex-direction: row
    flex-wrap: wrap
    .cover__media
      width: 62%
      display: block
      margin: auto

  &__pagination
    display: none

@media screen and (min-width: 1025px)
  .diptyque__scroller
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: 1fr
    grid-column-gap: var(--gutter)
    grid-row-gap: 0px
    grid-column: 1 / span 2

@media screen and (max-width: 1024px)
  .diptyque
    $self: &
    position: relative

    &--gutters
      margin: 0 var(--gutter)
      padding: 0

    &--mobileSlider
      #{$self}__scroller
        display: flex
        overflow-x: auto
        scroll-snap-type: x mandatory
        grid-column: 1 / span 2

        -ms-overflow-style: none
        scrollbar-width: none

        &::-webkit-scrollbar
          display: none

      #{$self}__pagination
        display: flex
        grid-column: 1 / span 2
        justify-content: center
        margin-top: -16px
        z-index: 1
        mix-blend-mode: difference

      #{$self}__bullet
        width: 10px
        height: 10px
        border-radius: 50%
        background-color: #C4C4C4
        margin: 0 5px

        &--active
          background-color: #fff

      .caption
        margin: 20px 0
        flex-grow: 0

        &:first-of-type
          margin-top: 0

      .cover__media
        display: block
        margin: auto

      #{$self}__slide
        scroll-snap-align: start
        width: 100%
        flex-shrink: 0

        &__0
          display: grid
          grid-template-rows: min-content 1fr min-content

          .cover__media
            width: 84%
</style>
