<template>
  <div
    class="media"
    ref="media"
    :class="{
      'media--fit': ratio,
      'media--ratio': ratio > 0,
      'media--loading': loading,
      'media--touch': touch,
      'media--tags-visible': tagsVisible,
      'media--tags-expanded': tagsExpanded,
      'media--video' : type === 'video'
    }"
  >
    <component
      :is="touch && tags && tags.length ? 'intersect' : 'transition'"
      :threshold="[0.65, 0.65]"
      @enter="tagsEnter"
      @leave="tagsLeave"
    >
      <component
        :is="mediaLink ? 'a' : 'div'"
        class="media__wrapper medialink"
        ref="wrapper"
        v-bind="mediaLink ? objectLink : null"
      >
        <img
          v-if="type === 'image'"
          class="media__figure"
          :loading="lazy"
          :fetchPriority="fetchPriority"
          :sizes="sizes"
          :srcset="srcset"
          :src="src"
          :style="{aspectRatio: ratio, objectFit: contain ? 'contain' : 'cover'}"
          @load="imagePreload"
          decoding="async"
        >
        <intersect
          v-if="type === 'video'"
          :threshold="[0.25, 0.27]"
          @leave="onLeave"
        >
          <video
            class="media__figure"
            ref="video"
            :loading="lazy"
            :src="src"
            :poster="poster"
            :muted="muted"
            :loop="true"
            :controls="false"
            :autoplay="true"
            :volume="volume"
            playsinline="playsinline"
            @loadedmetadata="videoLoadedMetadata"
          />
        </intersect>
        <span v-if="type === 'video'" v-show="muteDisplay" class="media__mute" @click="muted = !muted">
          {{ sound_text }}
        </span>
        <ul
          v-if="tags && tags.length"
          class="media__tags"
          :style="{
            width: naturalWidth,
            height: naturalHeight
          }"
        >
          <li
            v-for="tag, index in tags"
            :key="index"
            class="media__tag"
            :style="{
              '--x': tag.x,
              '--y': tag.y
            }"
            @touchstart="tagsExpanded ? $event.stopPropagation() : $event.preventDefault()"
          >
            <a
              class="media__tag-link"
              :href="tag.url"
              rel="noopener"
              target="_blank"
            >
              <span class="media__tag-label">
                {{ tag.label.split(' ').slice(0, -1).join(' ') }}
                <span class="media__tag-icon">
                  {{ tag.label.split(' ').slice(-1)[0] }}
                </span>
              </span>
            </a>
          </li>
        </ul>
      </component>
    </component>
    <div v-if="link || share" class="media__links">
      <a v-if="link" class="media__link" :href="link.url">{{ link.label }}</a>
      <a v-if="share" class="media__share" href="#">{{ share.label }}</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Intersect from 'vue-intersect'

let bus

export default {
  name: 'VMedia',

  components: {
    Intersect
  },

  props: {
    src: {
      type: String,
      required: true
    },
    lazy: {
      type: [String],
      required: false,
      default: 'lazy'
    },
    fetchPriority: {
      type: [String],
      required: false,
      default: 'low'
    },
    ratio: {
      type: Number,
      default: 0
    },
    srcset: {
      type: String,
      default: ''
    },
    sizes: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    link: {
      type: Object,
      default: null
    },
    share: {
      type: Object,
      default: null
    },
    mediaLink: {
      type: String,
      default: ''
    },
    tags: {
      type: [Array, null],
      default: null
    },
    muteDisplay : {
      type: Boolean,
      default: false
    },
    contain : {
      type: Boolean,
      default: false
    },
    muteVideo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      muted: true,
      sourceRatio: 1,
      viewportRatio: 1,
      tagsVisible: false,
      tagsExpanded: false,
      volume: 0.5,
      objectLink : {
        href: "",
        target: '_blank',
        rel: 'noopener'
      }
    }
  },

  created() {
    this.touchstart = this.touchstart.bind(this)
    this.imageLoad = this.imageLoad.bind(this)
    this.busUnmute = this.busUnmute.bind(this)
    this.bus = bus || (bus = new Vue())
    this.touch = 'ontouchstart' in window
    this.preloadImage = null
    this.volumeFrameRequest = null
    this.volumeTimeout = null
  },

  mounted() {
    if (this.$refs.video && this.muted) {
      // https://stackoverflow.com/a/51189390
      this.$refs.video.muted = true
    }

    this.bus.$on('unmute', this.busUnmute)
    this.resize()

    this.objectLink = {
      href: this.mediaLink,
      target: '_blank',
      rel: 'noopener'
    }

    window.addEventListener('scroll', this.handleMuted)
  },

  beforeDestroy() {
    removeEventListener('touchstart', this.touchstart)
    this.bus.$off('unmute', this.busUnmute)
  },

  watch: {
    src: {
      immediate: true,
      handler(src) {
        switch (this.type) {
          case 'image':
            this.loading = true
            break

          default:
            this.$emit('load')
            break
        }
      }
    },
    muted(muted) {
      muted || this.bus.$emit('unmute', this)
    }
  },

  computed: {
    sound_text() {
      return this.muted ? 'Sound off' : 'Sound on'
    },
    mimeType() {
      let type
      let subType = this.src
        .replace(/^.*\.([^.\?]+)(?:\?.*)?$/, '$1')
        .toLowerCase()

      switch (subType) {
        case 'jpg':
          subType = 'jpeg'
        case 'jpeg':
        case 'svg':
          type = 'image'
          break
        case 'png':
          type = 'image'
          break

        case 'mp4':
        case 'mov':
          type = 'video'
          break

        default:
          return
      }

      return `${type}/${subType}`
    },

    type() {
      const mimeType = this.mimeType

      if (!mimeType) {
        return
      }

      return mimeType.slice(0, mimeType.indexOf('/'))
    },

    naturalWidth() {
      return this.sourceRatio < this.viewportRatio
        ? '100%'
        : `${this.sourceRatio / this.viewportRatio * 100}%`
    },

    naturalHeight() {
      return this.sourceRatio < this.viewportRatio
        ? `${this.viewportRatio / this.sourceRatio * 100}%`
        : '100%'
    }
  },

  methods: {

    handleMuted() {
      if(this.muted) return
      const startY = window.scrollY
      const scroll_inverval = window.setInterval( async ()=> {
        const distance = Math.abs(startY - window.scrollY)
        if(distance > window.innerHeight) {
          this.muted = true
          window.clearInterval(scroll_inverval)
        }
      }, 500)
      
    },

    onLeave() {
      this.muted = false
    },

    async busUnmute(media) {
      this.muted = media !== this
    },

    tagsEnter() {
      this.tagsVisible = true
      addEventListener('touchstart', this.touchstart, { passive: false })
    },

    tagsLeave() {
      removeEventListener('touchstart', this.touchstart)
      this.tagsVisible = this.tagsExpanded = false
    },

    resize() {
      this.viewportRatio = this.$refs.wrapper.offsetWidth / this.$refs.wrapper.offsetHeight
    },

    imagePreload(event) {
      if (this.preloadImage) {
        this.preloadImage.removeEventListener('load', this.imageLoad)
        this.preloadImage = null
      }

      this.preloadImage = new Image()
      this.preloadImage.addEventListener('load', this.imageLoad, { once: true })
      this.preloadImage.src = event.target.currentSrc || event.target.src
    },

    imageLoad() {
      this.sourceRatio = this.preloadImage.width / this.preloadImage.height
      this.preloadImage = null
      this.loading = false
      this.$emit('load')
    },

    videoLoadedMetadata() {
      this.sourceRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight
    },

    touchstart(event) {
      if (this.$el.contains(event.target)) {
        this.tagsExpanded = !this.tagsExpanded && this.tagsVisible
      } else {
        this.tagsExpanded = false
      }
    }
  }
}
</script>

<style lang="sass">
.media
  transform: translate3d(0,0,0)
  --mute-offset: 0%
  position: relative

  &__wrapper
    overflow: hidden
    position: relative
    display: block

    @media screen and (max-width: 768px)
      padding-top: 0 !important

  &__figure
    // width: calc(100% + 2px)
    width: 100%
    object-fit: cover
    display: block
    height: 100%
    // margin: 0 -1px
    transition: .1s

  &__mute
    left: calc(20px + var(--mute-offset))
    bottom: 20px
    cursor: pointer
    z-index: 2
    position: absolute
    font-size: 11px
    text-transform: uppercase
    color: #fff
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0)
    mix-blend-mode: difference

  &__links
    margin-top: 6px
    width: 100%
    display: flex
    font-size: 10px
    text-transform: uppercase

  &__share
    margin-left: auto

  &__tags
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &__tag
    width: 48px
    height: 48px
    position: absolute
    top: calc(50% * (1 - var(--y)))
    left: calc(50% * (1 + var(--x)))
    transform: translate(-50%, -50%)
    user-select: none

    &::before
      content: ''
      background-color: #fff
      width: 20px
      height: 20px
      border-radius: 30px
      position: absolute
      top: 50%
      left: 50%
      opacity: 0
      transform: translate(-50%, -50%) scale(0)
      transition: opacity .4s, transform .01s .4s

  &__tag-link
    opacity: 0
    position: absolute
    width: max-content
    max-width: 160px
    left: 50%
    bottom: 0
    padding-bottom: 48px
    font-size: 10px
    text-transform: uppercase
    pointer-events: none
    touch-action: none
    transform: translate3d(-50%, 10px, 0)
      origin: 50% 100%
    transition: opacity .3s, transform .01s .3s

    &::after
      content: ''
      width: 0
      height: 0
      left: 50%
      bottom: 48px
      position: absolute
      border: 8px solid transparent
        top-color: rgba(0, 0, 0, .8)
        left-width: 7px
        right-width: 7px
      transform: translate(-50%, 99%)

  &__tag-label
    background-color: rgba(0, 0, 0, .8)
    color: #fff
    text-decoration: none
    padding: 10px
    display: inline-block

  &__tag-icon
    white-space: nowrap
    &::after
      content: '\F106'
      height: 8px
      font:
        family: 'icons'
        size: 8px
      line-height: 8px
      padding-left: 7px
      display: inline-block

  &:not(&--touch):hover &__tag::before,
  &--touch#{&}--tags-visible &__tag::before
    opacity: 1
    transform: translate(-50%, -50%) scale(1)
    transition: opacity .3s .1s, transform .4s

  &:not(&--touch):hover &__tag:hover::before,
  &--touch#{&}--tags-expanded &__tag:hover::before
    transition: transform .2s
    transform: translate(-50%, -50%) scale(1.2)

  &:not(&--touch) &__tag:hover &__tag-link,
  &--touch#{&}--tags-expanded &__tag-link
    opacity: 1
    pointer-events: auto
    touch-action: auto
    transform: translate3d(-50%, 0, 0)
      origin: 50% 100%
    transition: opacity .4s .1s, transform .4s

  &--touch &__tag-link
    pointer-events: auto
    touch-action: auto

  &--fit &__figure, &--fit &__wrapper
    height: 100%

  &--ratio &__figure
    //top: 0
    //left: 0
    //position: absolute

  &--ratio &__figure
    height: 100%

  &--ratio &__wrapper
    // height: auto

  &--loading &__figure
    opacity: 0
</style>
