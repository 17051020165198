<template>
  <v-main id="app" class="app" :class="{
    'show': opacity,
  }">
    <v-header fixed :class="{'invert' : invertHeader}" />
    <v-space style="height: 100vh; background-color: transparent; pointer-events: none;" />
    <v-cover
      style="z-index: -1; height: 100vh;"
      :src="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/MOBILE/1.mp4', 500, false) : cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593121/winter-22/Vid%C3%A9os/DEKSTOP/1.mp4', 1600, false)"
      :ratio="isMobile ? 9 / 16 : 16 / 9"
      sticky
      fullHeight
      :muteDisplay="true"
      :style="{ 'opacity' :vIndex===0 ? 1 : 0 }"
    />
    <v-cover
      style="z-index: -1; height: 100vh;"
      :src="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/MOBILE/2.mp4', 500, false) : cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/DEKSTOP/2.mp4', 1600, false)"
      :ratio="isMobile ? 9 / 16 : 16 / 9"
      sticky
      fullHeight
      :muteDisplay="true"
      :style="{ 'opacity' : vIndex===1 ? 1 : 0 }"
    />
    <v-cover
      style="z-index: -1; height: 100vh;"
      :src="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/MOBILE/3.mp4', 500, false) : cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593131/winter-22/Vid%C3%A9os/DEKSTOP/3.mp4', 1600, false)"
      :ratio="isMobile ? 9 / 16 : 16 / 9"
      sticky
      fullHeight
      :muteDisplay="true"
      :style="{ 'opacity' : vIndex===2 ? 1 : 0  }"
    />
    <v-cover
      style="z-index: -1; height: 100vh;"
      :src="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/MOBILE/4.mp4', 500, false) : cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/DEKSTOP/4.mp4', 1600, false)"
      :ratio="isMobile ? 9 / 16 : 16 / 9"
      sticky
      fullHeight
      :muteDisplay="true"
      :style="{ 'opacity' : vIndex===3 ? 1 : 0  }"
    />
    <v-cover
      style="z-index: -1; height: 100vh;"
      :src="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/MOBILE/5.mp4', 500, false) : cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/DEKSTOP/5.mp4', 1600, false)"
      :ratio="isMobile ? 9 / 16 : 16 / 9"
      sticky
      fullHeight
      :muteDisplay="true"
      :style="{ 'opacity' : vIndex===4 ? 1 : 0  }"
    />
    <v-cover
      style="z-index: -1; height: 100vh;"
      :src="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/MOBILE/6.mp4', 500, false) : cloudinary('https://res.cloudinary.com/neuvieme-page/video/upload/v1665593130/winter-22/Vid%C3%A9os/DEKSTOP/6.mp4', 1600, false)"
      :ratio="isMobile ? 9 / 16 : 16 / 9"
      sticky
      fullHeight
      :muteDisplay="true"
      :style="{ 'opacity' : vIndex===5 ? 1 : 0, 'position' : showFooter ? 'absolute' : 'fixed' }"
    />

    <v-cover-logo
      style="position: absolute; top: 0;"
      :logoImage="isMobile ? cloudinary('https://res.cloudinary.com/neuvieme-page/image/upload/v1665676445/winter-22/logo-mobile-v2.png', 600) : cloudinary('https://res.cloudinary.com/neuvieme-page/image/upload/v1665676246/winter-22/logo-desktop-v2.png?v=2', 1600)"
      logoSticky
      stickyTop
      :ratio="isMobile ? 600 / 314 : 4273 / 1697"
    />
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(20vh);" data-index="0" />
    <v-space />
    <v-duo
      class="trackinvert"
      data-index="0"
      gutters
      :style="{ padding: '0 32px' }"
      :slides="[
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129675/winter-22/Images%20Mobile/01_Mobile_YSL_310522__1996_12.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/01_YSL_310522__2393.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129675/winter-22/Images%20Mobile/01_Mobile_YSL_310522__1996_12.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/01_YSL_310522__2393.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129675/winter-22/Images%20Mobile/01_Mobile_YSL_310522__1996_12.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/01_YSL_310522__2393.jpg', '800', false)} 1600w,
      `,
      mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-winter22-looks&look=2022_3_22_045&utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 936 / 1398 : 1600 / 2379
    },
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129675/winter-22/Images%20Mobile/02_Mobile_YSL_310522__0573_16.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129857/winter-22/Images%20Desktop/02_YSL_310522__2393_11.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129675/winter-22/Images%20Mobile/02_Mobile_YSL_310522__0573_16.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129857/winter-22/Images%20Desktop/02_YSL_310522__2393_11.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129675/winter-22/Images%20Mobile/02_Mobile_YSL_310522__0573_16.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129857/winter-22/Images%20Desktop/02_YSL_310522__2393_11.jpg', '800', false)} 1600w,
      `,
      mediaLink: 'https://www.ysl.com/en-en/shoulder-bags/le-5-a-7-hobo-bag-in-patent-leather-6572280UF0W1000.html?utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 936 / 1392 : 1600 / 2379
    },
  ]"
    />
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(-20vh);" data-index="1" />
    <v-space />
    <v-cover-logo
      :logoImage="require('@/assets/images/SL_newLogo.svg')"
      logoSticky
      stickyBottom
      :ratio="isMobile ? 260 / 91 :869/191"
    />
    <v-space v-if="!isMobile"/>
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(20vh);" data-index="1" />
    <v-duo-three-columns
      class="trackinvert"
      data-index="1"
      :inverted="isMobile"
      gutters
      :style="{ padding: isMobile ? '64px' : '20vh 32px' }"
      :slides="[
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/04_Mobile_SAINT_LAURENT_WINTER_22_28_3.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129848/winter-22/Images%20Desktop/03_SAINT_LAURENT_WINTER_22_18.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/04_Mobile_SAINT_LAURENT_WINTER_22_28_3.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129848/winter-22/Images%20Desktop/03_SAINT_LAURENT_WINTER_22_18.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/04_Mobile_SAINT_LAURENT_WINTER_22_28_3.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129848/winter-22/Images%20Desktop/03_SAINT_LAURENT_WINTER_22_18.jpg', '1200', false)} 1600w,
      `,
      ratio: isMobile ? 744 / 1110 : 1600 / 1135
    },
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129674/winter-22/Images%20Mobile/03_Mobile_SAINT_LAURENT_WINTER_22_28_2.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129835/winter-22/Images%20Desktop/04_SAINT_LAURENT_WINTER_22_28.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129674/winter-22/Images%20Mobile/03_Mobile_SAINT_LAURENT_WINTER_22_28_2.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129835/winter-22/Images%20Desktop/04_SAINT_LAURENT_WINTER_22_28.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129674/winter-22/Images%20Mobile/03_Mobile_SAINT_LAURENT_WINTER_22_28_2.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129835/winter-22/Images%20Desktop/04_SAINT_LAURENT_WINTER_22_28.jpg', '800', false)} 1600w,
      `,
      mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-winter22-looks&look=2022_3_22_006&utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 744 / 1110 : 1600 / 2328
    },
  ]"
    />
    <v-space v-if="!isMobile"/>
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(-20vh);" data-index="2" />
    <v-cover-logo
      :logoImage="require('@/assets/images/SL_newLogo.svg')"
      logoSticky
      stickyTop
      :ratio="869/191"
    />
    <v-space v-if="!isMobile"/>
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(20vh);" data-index="2" />
    <v-duo
      class="trackinvert special special_1"
      data-index="2"
      :style="{ padding: isMobile ? '16px 16px' : '0 32px' }"
      :slides="[
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/05_Mobile_SAINT_LAURENT_WINTER_22_22.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/05_YSL_310522__2393_8.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/05_Mobile_SAINT_LAURENT_WINTER_22_22.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/05_YSL_310522__2393_8.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/05_Mobile_SAINT_LAURENT_WINTER_22_22.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/05_YSL_310522__2393_8.jpg', '800', false)} 1600w,
      `,
      mediaLink: isMobile ? 'https://www.ysl.com/en-en/search?cgid=w-winter22-looks&look=2022_3_22_018&utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int' : 'https://www.ysl.com/en-en/le-57/le-57-shoulder-bag-in-quilted-laquered-canvas-and-leather-698567FAAS31000.html?utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 1026 / 1515 : 1600 / 2379
    },
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/06_Mobile_SAINT_LAURENT_WINTER_22_02.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/06_YSL_310522__2393_9.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/06_Mobile_SAINT_LAURENT_WINTER_22_02.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/06_YSL_310522__2393_9.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129676/winter-22/Images%20Mobile/06_Mobile_SAINT_LAURENT_WINTER_22_02.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129849/winter-22/Images%20Desktop/06_YSL_310522__2393_9.jpg', '800', false)} 1600w,
      `,
      mediaLink: isMobile ? 'https://www.ysl.com/en-en/le-57/le-57-shoulder-bag-in-quilted-laquered-canvas-and-leather-698567FAAS31000.html?utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int' : 'https://www.ysl.com/en-en/search?cgid=w-winter22-looks&look=2022_3_22_018&utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 1026 / 1532 : 1600 / 2379
    },
  ]"
    >
    </v-duo>
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(-20vh);" data-index="3" />
    <v-space/>
    <v-cover-logo
      :logoImage="isMobile ? require('@/assets/images/logo_name_mobile.svg') : require('@/assets/images/LOGO_NAME.svg')"
      style="background-color: white"
      logoSticky
      stickyBottom
      background
      :ratio="79/19"
    />
    <v-space/>
    <v-duo
      class="trackinvert special special_2"
      data-index="3"
      gutters
      :style="{ padding: isMobile ? '0 16px' : '0 32px' }"
      :slides="[
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/07_Mobile_YSL_310522__2566_6.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129859/winter-22/Images%20Desktop/07_YSL_310522__2393_6.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/07_Mobile_YSL_310522__2566_6.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129859/winter-22/Images%20Desktop/07_YSL_310522__2393_6.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/07_Mobile_YSL_310522__2566_6.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129859/winter-22/Images%20Desktop/07_YSL_310522__2393_6.jpg', '800', false)} 1600w,
      `,
      ratio: isMobile ? 1026 / 1529 : 1600 / 2379
    },
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/08_Mobile_YSL_310522__2393_6.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/08_YSL_310522__2393_7.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/08_Mobile_YSL_310522__2393_6.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/08_YSL_310522__2393_7.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/08_Mobile_YSL_310522__2393_6.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/08_YSL_310522__2393_7.jpg', '800', false)} 1600w,
      `,
      ratio: isMobile ? 1026 / 1518 : 1600 / 2379
    },
  ]"
    >
    </v-duo>
    <v-space/>
    <v-cover-logo
      :logoImage="require('@/assets/images/SL_newLogo.svg')"
      logoSticky
      stickyTop
      :ratio="869/191"
    />
    <v-space v-if="!isMobile"/>
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(20vh);" data-index="3" />
    <v-duo
      data-index="5"
      medium
      class="last__duo trackinvert"
      :style="{ padding: isMobile ? '64px' : '14vw 20vw'  }"
      :slides="[
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/09_Mobile_SAINT_LAURENT_WINTER_22_25.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129859/winter-22/Images%20Desktop/09_SAINT_LAURENT_WINTER_22_25.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/09_Mobile_SAINT_LAURENT_WINTER_22_25.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129859/winter-22/Images%20Desktop/09_SAINT_LAURENT_WINTER_22_25.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129677/winter-22/Images%20Mobile/09_Mobile_SAINT_LAURENT_WINTER_22_25.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129859/winter-22/Images%20Desktop/09_SAINT_LAURENT_WINTER_22_25.jpg', '800', false)} 1600w,
      `,
      mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-winter22-looks&look=2022_3_22_055&utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 744 / 1109 : 1600 / 2384
    },
    {
      src: cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129678/winter-22/Images%20Mobile/10_Mobile_SAINT_LAURENT_WINTER_22_06.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129856/winter-22/Images%20Desktop/10_SAINT_LAURENT_WINTER_22_06.jpg', '600', false),
      srcset: `
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129678/winter-22/Images%20Mobile/10_Mobile_SAINT_LAURENT_WINTER_22_06.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129856/winter-22/Images%20Desktop/10_SAINT_LAURENT_WINTER_22_06.jpg', '600', false)} 1200w,
        ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129678/winter-22/Images%20Mobile/10_Mobile_SAINT_LAURENT_WINTER_22_06.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129856/winter-22/Images%20Desktop/10_SAINT_LAURENT_WINTER_22_06.jpg', '800', false)} 1600w,
      `,
      mediaLink: 'https://www.ysl.com/en-en/clutch-and-minaudieres/vicky-miniaudiere-in-plexiglass-716543JA70J1000.html?utm_source=digitaltool_winter22&utm_medium=email&utm_campaign=digitaltool_winter22_yslcom_int',
      ratio: isMobile ? 744 / 1109 : 1600 / 2384
    },
  ]"
    ></v-duo>
    <v-space v-if="!isMobile"/>
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(-20vh);" data-reached="nope" data-index="4" />
    <v-cover-logo
      :logoImage="isMobile ? require('@/assets/images/logo_photo_mobile.svg') : require('@/assets/images/logo_photo.svg')"
      logoSticky
      stickyBottom
      :ratio="isMobile ? 100 / 149 : 869/227"
    />
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(20vh);" data-reached="nope" data-index="4" />
    <v-cover
      class="trackinvert"
      data-index="6"
      gutters
      style="z-index: 4; position: relative; display: block; background-color: white"
      :style="{ padding: isMobile ? '128px 0' : '32px 0px', '--gutter': isMobile ? '16px' : '32px' }"
      :src="
    cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129813/winter-22/Images%20Mobile/11_Mobile_SAINT_LAURENT_WINTER_22_25-1.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/11_YSL_310522__0573_17.jpg', '600', false)
  "
      :srcset="
    `
     ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129813/winter-22/Images%20Mobile/11_Mobile_SAINT_LAURENT_WINTER_22_25-1.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/11_YSL_310522__0573_17.jpg', '600', false)} 1200w,
     ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129813/winter-22/Images%20Mobile/11_Mobile_SAINT_LAURENT_WINTER_22_25-1.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/11_YSL_310522__0573_17.jpg', '800', false)} 1600w,
     ${cloudinary(isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129813/winter-22/Images%20Mobile/11_Mobile_SAINT_LAURENT_WINTER_22_25-1.jpg' : 'https://res.cloudinary.com/neuvieme-page/image/upload/v1665129864/winter-22/Images%20Desktop/11_YSL_310522__0573_17.jpg', '2600', false)} 2600w,
  `
  "
      sizes="(max-width: 2600px) 100vw, 2600px"
      :ratio="isMobile ? 1029 / 1533 : 1300/951"
    />
    <v-space class="trackpoint" style="--gutter: 0px; transform: translateY(-20vh);" data-index="5" />

    <v-cover-logo
      :logoImage="require('@/assets/images/SL_newLogo.svg')"
      logoSticky
      stickyBottom
      :ratio="869/191"
    />
    <v-space class="trackpoint" style="--gutter: 0px;" data-index="5"></v-space>
    <v-footer/>
    <v-space v-if="isMobile" style="position: fixed; bottom: 0;left: 0; height: 200px; z-index: 9;" :style="{'opacity' : showFooter ? 1 : 0 }" />
    <v-cookies :gatag="'G-PJHBPFXWKN'"></v-cookies>
  </v-main>
</template>
<script>
import { cloudinary } from '@/cloudinary'
import VSpace from '../../../../packages/core/src/components/VSpace.vue'

export default {
  components: { VSpace },
  data() {
    return {
      isMobile: true,
      cloudinary,
      vIndex: 0,
      opacity: 0,
      showFooter: false,
      invertHeader: false,
      currentInview: false,
      forceInvert: false
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })

    const observer = new IntersectionObserver((observables) => {
      observables.forEach((observable) => {
        if (observable.intersectionRatio > 0 && observable.target.classList.contains('trackpoint')) {
          this.vIndex = parseInt(observable.target.getAttribute('data-index'), 10)
        }
        if (observable.intersectionRatio > 0 && observable.target.classList.contains('trackinvert')) {
          this.currentInview = observable.target
        }
      })
    }, {
      threshold: [0, 0.25, 0.5, 0.75, 1]
    })

    window.setTimeout(() => {
      this.onResize()
      // items
      const items = document.querySelectorAll('.trackpoint')
      items.forEach(function(item) {
        observer.observe(item)
      })
      // trackinvert
      const itemsInvert = document.querySelectorAll('.trackinvert')
      itemsInvert.forEach(function(item) {
        observer.observe(item)
      })
      this.doInvert(document.querySelector('.trackinvert'))
    }, 100)

    window.setTimeout(() => {
      this.opacity = 1
    }, 300)

    window.onscroll = (ev) => {
      if ((window.innerHeight + window.scrollY + 10) >= document.body.offsetHeight) {
        this.showFooter = true
      } else {
        this.showFooter = false
      }
      this.doInvert(this.currentInview)
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768
    },
    doInvert(el) {
      if (!el) return
      const rect = el.getBoundingClientRect()

      if (el.classList.contains('special')) {
        if (el.classList.contains('special_1')) {
          if (rect.top < 0) {
            this.invertHeader = false
            return
          }
        }

        if (el.classList.contains('special_2')) {
          if (rect.bottom > 0) { this.invertHeader = false } else { this.invertHeader = true }
          return
        }
      }

      const spaceBetween = this.isMobile ? 36 : 62
      if (rect.top < spaceBetween && rect.bottom > 0) {
        this.invertHeader = false
      } else {
        this.invertHeader = true
      }
    }
  }
}
</script>
<style lang="sass">
  .app
    opacity: 0
    transition: opacity 0.15s
    &.show
      opacity: 1

  body, html
    overscroll-behavior: none

  .title
    position: relative

  .invert
    .header__content
      filter: invert(1)

  .v-title
    position: absolute
    top: 50%
    z-index: 2
    left: 50%
    transform: translate(-50%)
    width: calc(100vw - var(--gutter) * 2)

  .m-1-top
    margin-top: calc(var(--gutter) * 2)
    @media screen and (min-width: 768px)
      margin-top: calc(var(--gutter))
  // .no-mobile
  //   @media screen and (max-width: 767px)
  //     display: none
  .space
    --gutter: 32px

  .media__wrapper
    padding-top: 0 !important
</style>
