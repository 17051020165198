<template>
    <div class="coverLogo" :class="{
      'coverLogo--stickyTop': stickyTop,
      'coverLogo--stickyBottom': stickyBottom
    }">
      <img
        :src="logoImage"
        ref="coverImage"
        class="coverLogo__logoImage"
        :class="{
          'coverLogo__logoImage--stickyTop': stickyTop,
          'coverLogo__logoImage--stickyBottom': stickyBottom
        }"
        :style="{ 'aspect-ratio': ratio }"
        alt="Logo SL" />
    </div>
</template>

<script>
export default {
  name: 'VCoverLogo',

  props: {
    logoImage: {
      type: String,
      default: '',
    },
    logoSticky: {
      type: Boolean,
      default: false,
    },
    stickyTop: {
      type: Boolean,
      default: false,
    },
    stickyBottom: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Number,
      required: false,
    },
  },

  methods: {
    getLogoHeight() {
      let height = this.$refs.coverImage.clientHeight
      this.$refs.coverImage.style.setProperty('--height', height + 'px')
    }
  },

  mounted() {
    this.$refs.coverImage.onload = () => {
      this.getLogoHeight()
    }
  }
}
</script>

<style lang="sass">
.coverLogo
  position: relative
  background-color: transparent
  height: 100vh
  width: 100%
  z-index: 1
  pointer-events: none
  transform: translate3d(0,0,0)

  &.no-filter
    .coverLogo__logoImage
      filter: none

  &--stickyTop
    margin-bottom: -100vh
    height: 160vh

    @media screen and (max-width: 768px)
      margin-bottom: calc(-150 * var(--fvh))
      height: calc(200 * var(--fvh))

  &--stickyBottom
    margin-top: calc(-90 * var(--fvh))
    height: calc(190 * var(--fvh))

  &__logoImage
    width: 45%
    top: 50%
    left: 50%
    position: absolute
    display: block
    z-index: 0
    transform: translate(-50%, -50%)
    filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 0.25))

    @media screen and (max-width: 768px)
      left: 0
      margin: auto
      width: 70%
      object-fit: contain


    &--stickyTop
      top: 50%
      margin-top: 50vh
      position: -webkit-sticky
      position: sticky
      transform: translate(-50%, -50%)

      @media screen and (max-width: 768px)
        top: calc(50 * var(--fvh))
        margin-bottom: calc(-100 * var(--fvh))
        margin-top: calc(50 * var(--fvh))
        height: calc(150 * var(--fvh))
        transform: translate(0%, -50%)

    &--stickyBottom
      bottom: calc(50 * var(--fvh))
      margin-bottom: calc(calc(50 * var(--fvh)) - var(--height)) 
      position: -webkit-sticky
      position: sticky
      transform: translate(-50%, -50%)

      @media screen and (max-width: 768px)
        transform: translate(0%, -50%)
        top: calc(50 * var(--fvh))

</style>
