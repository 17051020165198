var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"media",staticClass:"media",class:{
    'media--fit': _vm.ratio,
    'media--ratio': _vm.ratio > 0,
    'media--loading': _vm.loading,
    'media--touch': _vm.touch,
    'media--tags-visible': _vm.tagsVisible,
    'media--tags-expanded': _vm.tagsExpanded,
    'media--video' : _vm.type === 'video'
  }},[_c(_vm.touch && _vm.tags && _vm.tags.length ? 'intersect' : 'transition',{tag:"component",attrs:{"threshold":[0.65, 0.65]},on:{"enter":_vm.tagsEnter,"leave":_vm.tagsLeave}},[_c(_vm.mediaLink ? 'a' : 'div',_vm._b({ref:"wrapper",tag:"component",staticClass:"media__wrapper medialink"},'component',_vm.mediaLink ? _vm.objectLink : null,false),[(_vm.type === 'image')?_c('img',{staticClass:"media__figure",style:({aspectRatio: _vm.ratio, objectFit: _vm.contain ? 'contain' : 'cover'}),attrs:{"loading":_vm.lazy,"fetchPriority":_vm.fetchPriority,"sizes":_vm.sizes,"srcset":_vm.srcset,"src":_vm.src,"decoding":"async"},on:{"load":_vm.imagePreload}}):_vm._e(),(_vm.type === 'video')?_c('intersect',{attrs:{"threshold":[0.25, 0.27]},on:{"leave":_vm.onLeave}},[_c('video',{ref:"video",staticClass:"media__figure",attrs:{"loading":_vm.lazy,"src":_vm.src,"poster":_vm.poster,"loop":true,"controls":false,"autoplay":true,"volume":_vm.volume,"playsinline":"playsinline"},domProps:{"muted":_vm.muted},on:{"loadedmetadata":_vm.videoLoadedMetadata}})]):_vm._e(),(_vm.type === 'video')?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.muteDisplay),expression:"muteDisplay"}],staticClass:"media__mute",on:{"click":function($event){_vm.muted = !_vm.muted}}},[_vm._v(" "+_vm._s(_vm.sound_text)+" ")]):_vm._e(),(_vm.tags && _vm.tags.length)?_c('ul',{staticClass:"media__tags",style:({
          width: _vm.naturalWidth,
          height: _vm.naturalHeight
        })},_vm._l((_vm.tags),function(tag,index){return _c('li',{key:index,staticClass:"media__tag",style:({
            '--x': tag.x,
            '--y': tag.y
          }),on:{"touchstart":function($event){_vm.tagsExpanded ? $event.stopPropagation() : $event.preventDefault()}}},[_c('a',{staticClass:"media__tag-link",attrs:{"href":tag.url,"rel":"noopener","target":"_blank"}},[_c('span',{staticClass:"media__tag-label"},[_vm._v(" "+_vm._s(tag.label.split(' ').slice(0, -1).join(' '))+" "),_c('span',{staticClass:"media__tag-icon"},[_vm._v(" "+_vm._s(tag.label.split(' ').slice(-1)[0])+" ")])])])])}),0):_vm._e()],1)],1),(_vm.link || _vm.share)?_c('div',{staticClass:"media__links"},[(_vm.link)?_c('a',{staticClass:"media__link",attrs:{"href":_vm.link.url}},[_vm._v(_vm._s(_vm.link.label))]):_vm._e(),(_vm.share)?_c('a',{staticClass:"media__share",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.share.label))]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }